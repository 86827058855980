// Libs:
import React from 'react';
// css:
import "../../../assets/styles/pages/style.css";


function Paragraph({ text }) {
    return (
        <p>
            {text}
        </p>
    );
};
export default Paragraph;