import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";
import UnorderedList from "../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../components/Links";




const crisis_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Program Description"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "A crisis occurs when an individual presents with a sudden, unanticipated, or potentially dangerous behavioral health condition, episode or behavior. Crisis intervention services are provided to stabilize or prevent an escalation of crisis, determine the needed services, and assist the individual in receiving the least restrictive, most effective treatment available.",
            "Kentucky’s 14 regional community mental health centers (CMHCs) provide services for individuals experiencing an IDD or behavioral health crisis 24 hours a day, 7 days a week, 365 days a year and may be contacted by calling 988 or the CMHC’s crisis hotline.",

            <><Links linkTypeID="536" /></>

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "988 Suicide & Crisis Lifeline"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [

            <>Kentuckians experiencing a mental health or substance use crisis can call, chat or text with a trained crisis counselor through the 988 Suicide & Crisis Lifeline. This free, confidential, and anonymous service is available 24/7. Individuals can call or text 988 or can chat by visiting <a href='https://988lifeline.org/chat/'>https://988lifeline.org/chat/</a> to speak with a trained crisis counselor for emotional support and connection to local mental health, substance use, and community resources. Information on Kentucky’s 988 system can be found by visiting <a href='https://988.ky.gov/'>https://988.ky.gov/</a>.</>
            

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "Behavioral Health Crisis Services"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [

            <>
            <br/> <b>Residential Crisis Stabilization Units </b> <br/>

            Residential crisis stabilization services are provided in Residential Crisis Stabilization Units (RCSUs). These short-term residential services are provided to individuals experiencing a behavioral health crisis who need overnight care do not need hospitalization.  

            </>,

            "RCSUs provide crisis screening, assessment, and stabilization interventions. Services may also include developing a crisis plan, individual, group, and family therapy, psychoeducation, stabilization of withdrawal management symptoms, medication management with extended on-site monitoring, care coordination, and peer support. ",

            <><Links linkTypeID="718" /></>,

            <><b>Walk-In Crisis Intervention </b></>,

            <>Kentucky Community Mental Health Centers (CMHCs) provide walk-in crisis services at many clinic locations. Contact your local <a href='/crisisnos'>CMHC Crisis Line</a> to find a walk-in location near you. <br/></>

    
            

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 70,
        content: "Developmental and Intellectual Disabilities Services"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 80,
        content: [
            "Individuals with a diagnosis of intellectual or developmental disability may also receive additional emergency services to provide the support needed to remain in the community. Individuals not experiencing a crisis but seeking to apply for or be connected to services may contact their corresponding Community Mental Health/Intellectual Disability Center (CMHC).",
            <><Links linkTypeID="537" /></>
        ]
    }
    










];

export default crisis_Content;