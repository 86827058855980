import React, { useState } from 'react';
import ProviderMap from '../components/ProviderMap';
import ProviderOptions from '../components/ProviderOptions';
import ProviderSearch from '../components/ProviderSearch';
import ProviderContact from '../components/ProviderContact';
import GeneratePDF from '../components/GeneratePDF';
import Navbar from '../layouts/Navbar';
import Footer from '../layouts/Footer';
import ProviderProfile from '../components/ProviderProfile'; 
import { Modal, Button, Pagination } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/styles/pages/ProviderDir.css';
import axios from 'axios';

function ProviderDirectory() {
    const [formData, setFormData] = useState({
        isStateWide: false,
        counties: [],
        groups: [],
        services: [],
        otherProviders: [],
        selectServices: []
    });

    const [results, setResults] = useState([]);
    const [isSearchResults, setIsSearchResults] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const [providerName, setProviderName] = useState("");   
    const [resetSearchError, setResetSearchError] = useState(0);
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [selectedProviderProfile, setSelectedProviderProfile] = useState(null); // State to hold provider profile data
    const [currentPage, setCurrentPage] = useState(1); // For pagination
    const resultsPerPage = 25; // Number of results per page

    const handleProviderNameSearch = (results) => {
        setResults(Array.isArray(results) ? results : []); // Ensure results is an array
        setIsSearchResults(true);  
        setHasSearched(true);
        setCurrentPage(1); // Reset to first page on new search
    };

    const handleReset = () => {
        setFormData({
            isStateWide: false,
            counties: [],
            groups: [],
            services: [],
            otherProviders: [],
            selectServices: []
        });
        setResults([]);
        setIsSearchResults(false);
        setHasSearched(false);
        setProviderName(""); 
        setResetSearchError(prev => prev + 1); 
        setSelectedProviderProfile(null); // Reset selected provider profile
        setCurrentPage(1); // Reset to first page on reset
    };

    const handleSubmit = async (data) => {
        try {
            //const response = await axios.post('https://localhost:7245/providers/search-all', data);
            const response = await axios.post('https://providerdirectory.dbhdidtest.ky.gov/api/providers/search-all', data);
            //const response = await axios.post('https://dbhdidtest.ky.gov/provdirapi/providers/search-all', data); 
            //const response = await axios.post('https://dbhdid.ky.gov/provdirapi/providers/search-all', data);           
            const searchResults = response.data || [];
            setResults(Array.isArray(searchResults) ? searchResults.filter(result => result) : []); // Filter out null or empty results, ensure array
            setIsSearchResults(true);
            setHasSearched(true);
            setCurrentPage(1); // Reset to first page on new search
        } catch (error) {
            //console.error('Error performing search:', error);
            setResults([]);
            setIsSearchResults(false);
            setHasSearched(true);
            setCurrentPage(1); // Reset to first page on error
        }
    };

    const handleProviderProfileClick = async (providerUid) => {
        try {
            //const response = await fetch(`https://localhost:7245/providers/generate-profile/${providerUid}`);
            const response = await fetch(`https://providerdirectory.dbhdidtest.ky.gov/api/providers/generate-profile/${providerUid}`);
            //const response = await fetch(`https://dbhdidtest.ky.gov/provdirapi/providers/generate-profile/${providerUid}`);
            //const response = await fetch(`https://dbhdid.ky.gov/provdirapi/providers/generate-profile/${providerUid}`);
            const profileData = await response.json();
            setSelectedProviderProfile(profileData);
            setShowModal(true); // Show modal on click
        } catch (error) {
            console.error('Error fetching provider profile:', error);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false); // Hide modal
    };

    // Pagination logic
    const totalPages = Math.ceil(results.length / resultsPerPage);
    const paginationItems = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
                {i}
            </Pagination.Item>
        );
    }
    const currentResults = results.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage);

    return (
        <>
            <Navbar />
            <div className="container">
                <div className="providerdirectory-header">Provider Directory
                    <div className="providerdirectory-green-underline"></div>
                    <h5>
                    Make your selections and click "Submit" to display providers.<br />
                    Click "Reset" to clear all the selections.
                   </h5>
                </div>          
                <div className='providerdirectory-bodyText'>
                    <div className="providerdirectory-options">
                        <ProviderOptions formData={formData} setFormData={setFormData} />
                        <ProviderMap 
                            formData={formData} 
                            setFormData={setFormData} 
                            onSubmit={handleSubmit} 
                            onReset={handleReset} 
                            noResults={!isSearchResults && hasSearched}
                        />                        
                        <br />
                        <br />
                        <ProviderSearch
                            providerName={providerName}
                            setProviderName={setProviderName}
                            onSearch={handleProviderNameSearch} 
                            resetError={resetSearchError}
                        />
                        <br />
                        <br />
                        <ProviderContact />
                    </div>
                    {hasSearched && isSearchResults && (
                        <div className="mt-3">
                            {results.length > 0 && (
                                <div className="mt-2 p-3">
                                    <div className="text-left mb-3">Number of providers: {results.length}</div>
                                    <GeneratePDF results={results} className="btn btn-primary btn-custom" />
                                    <Pagination className="justify-content-center flex-wrap">{paginationItems}</Pagination>
                                    <table className="table table-bordered mt-3">
                                        <tbody>
                                            {currentResults.map((provider, index) => (
                                                <tr key={index}>
                                                    <td className="align-top p-3">
                                                        <strong>{provider.providerDesc}</strong><br />
                                                        {/* Display Physical Address */}
                                                        {provider.addresses && Array.isArray(provider.addresses) && provider.addresses
                                                            .filter(address => address.addressType === 'Physical')
                                                            .map((address, idx) => (
                                                                <div key={idx}>
                                                                    {address.address1 && <div>{address.address1}</div>}
                                                                    {address.address2 && <div>{address.address2}</div>}
                                                                    {address.address3 && <div>{address.address3}</div>}
                                                                    {address.address4 && <div>{address.address4}</div>}
                                                                    {(address.city || address.state || address.zip) && (
                                                                        <div>
                                                                            {address.city && address.state && address.zip ? (
                                                                                <div>{address.city}, {address.state} {address.zip}</div>
                                                                            ) : (
                                                                                <>
                                                                                    {address.city && <span>{address.city}, </span>}
                                                                                    {address.state && <span>{address.state} </span>}
                                                                                    {address.zip && <span>{address.zip}</span>}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        {/* Display Mailing Address if exists */}
                                                        {provider.addresses && Array.isArray(provider.addresses) && provider.addresses
                                                            .filter(address => address.addressType === 'Mailing' && (address.address1 || address.address2 || address.address3 || address.address4 || address.city || address.state || address.zip))
                                                            .length > 0 && (
                                                                <div>
                                                                    <strong>Mailing Address:</strong><br />
                                                                    {provider.addresses
                                                                        .filter(address => address.addressType === 'Mailing' && (address.address1 || address.address2 || address.address3 || address.address4 || address.city || address.state || address.zip))
                                                                        .map((address, idx) => (
                                                                            <div key={idx}>
                                                                                {address.address1 && <div>{address.address1}</div>}
                                                                                {address.address2 && <div>{address.address2}</div>}
                                                                                {address.address3 && <div>{address.address3}</div>}
                                                                                {address.address4 && <div>{address.address4}</div>}
                                                                                {(address.city || address.state || address.zip) && (
                                                                                    <div>
                                                                                        {address.city && address.state && address.zip ? (
                                                                                            <div>{address.city}, {address.state} {address.zip}</div>
                                                                                        ) : (
                                                                                            <>
                                                                                                {address.city && <span>{address.city}, </span>}
                                                                                                {address.state && <span>{address.state} </span>}
                                                                                                {address.zip && <span>{address.zip}</span>}
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            )}
                            {provider.web && (
                            <div>
                                <strong>Website:</strong> <a href={provider.web.startsWith('http') ? provider.web : `http://${provider.web}`} target="_blank" 
                                rel="noopener noreferrer">
                                {provider.web}
                                </a>
                                <br />
                            </div>
                            )}
                                                        {provider.hasProfile && (
                                                        <div className="provider-profile-container">
                                                            <button
                                                                onClick={() => handleProviderProfileClick(provider.providerUid)}
                                                                className="btn btn-custom provider-profile-link"
                                                            >Provider Profile</button><br />
                                                        </div>
                                                        )}
                                                        {provider.contact1 && (
                                                            <div>
                                                                <br />
                                                                <strong>Contact Person:</strong> {provider.contact1}<br />
                                                            </div>
                                                        )}
                                                        {provider.contact2 && (
                                                            <div>
                                                                <strong>Additional Contact:</strong> {provider.contact2}<br />
                                                            </div>
                                                        )}
                                                        {provider.contact3 && (
                                                            <div>
                                                                <strong>Additional Contact:</strong> {provider.contact3}<br />
                                                            </div>
                                                        )}
                                                        {provider.contact4 && (
                                                            <div>
                                                                <strong>Additional Contact:</strong> {provider.contact4}<br />
                                                            </div>
                                                        )}
                                                        {provider.email && (
                                                            <div>
                                                                <strong>Email:</strong> <a href={`mailto:${provider.email}`} className="hyperLink">{provider.email}</a><br />
                                                            </div>
                                                        )}
                                                        {provider.email2 && (
                                                            <div>
                                                                <strong>Alt Email:</strong> <a href={`mailto:${provider.email2}`} className="hyperLink">{provider.email2}</a><br />
                                                            </div>
                                                        )}
                                                        {provider.phone && (
                                                            <div>
                                                                <strong>Contact Number:</strong> {provider.phone}<br />
                                                            </div>
                                                        )}
                                                        {provider.altPhone && (
                                                            <div>
                                                                <strong>Alt Phone:</strong> {provider.altPhone}<br />
                                                            </div>
                                                        )}
                                                        {provider.crisisLine && (
                                                            <div>
                                                                <strong>Crisis Number:</strong> {provider.crisisLine}<br />
                                                            </div>
                                                        )}
                                                        {provider.tollFreePhone && (
                                                            <div>
                                                                <strong>Alternate Number:</strong> {provider.tollFreePhone}<br />
                                                            </div>
                                                        )}
                                                        {provider.fax && (
                                                            <div>
                                                                <strong>Fax:</strong> {provider.fax}<br />
                                                            </div>
                                                        )}
                                                        {provider.altFax && (
                                                            <div>
                                                                <strong>Alt Fax:</strong> {provider.altFax}<br />
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="align-top p-3">
                                                     <strong>Services Available:</strong><br />
                                {provider.providerServices && Array.isArray(provider.providerServices) && provider.providerServices.length > 0 ? (
                                 provider.providerServices.map((service, idx) => (
                                                     <div key={idx}>{service.serviceDesc}</div>
                                                    ))
                                                      ) : (
                                                    <div>For information about services offered, please use the contact details listed.</div>
                                                        )}
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <Pagination className="justify-content-center flex-wrap">{paginationItems}</Pagination>
                                </div>
                            )}
                        </div>
                    )}
                    {/* Render the ProviderProfile component if selectedProviderProfile is set */}
                    <Modal show={showModal} onHide={handleCloseModal} size="lg">
                        <Modal.Header closeButton className="modal-header-custom">
                            <Modal.Title>Provider Profile</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedProviderProfile && <ProviderProfile profile={selectedProviderProfile} />}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" className="modal-button-custom" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ProviderDirectory;
