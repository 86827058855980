const SanitizeHref = (href) => {
    // console.log(href)
    if(href == undefined || href == null){
        return ""; 
    }
    if (href.startsWith("http") || !href.includes("documents/")) {
        return href;
    }
    const testDomain = "https://dbhdidtest.ky.gov/";
    const domain = "https://dbhdid.ky.gov/";
    const windowUrl = window.location.href;
    if (windowUrl.includes("localhost") || windowUrl.includes("dbhdidtest")) {
        return testDomain + href;
    } else {
        return domain + href;
    }
};

export default SanitizeHref;