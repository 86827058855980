import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";


const dUI_Content = [


    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <>
                Kentucky Revised Statute 189A.040(5) requires all DUI services to be received in an alcohol or sub-stance abuse education or treatment program or facility that is licensed, regulated, and monitored by the Cabinet for Health and Family Services.
                Therefore, Kentucky will not accept online DUI course completions. Please access the Provider Directory to identify the licensed, state-certified DUI provider(s) in your county.,
            </>,
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Program Description"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            "The Driving Under the Influence (DUI) Program is responsible for monitoring and regulating the statewide network of Driving Under the Influence programs that are licensed and certified to provide alcohol and other drug assessments, education and treatment services to persons convicted of DUI.",
            "The program operates under the authority of administrative regulation 908 KAR 1:310, in accordance with the provisions of Kentucky Revised Statute 189A."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "DUI Contact Information"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            <>
                <ul>
                    <li> Lynn Lockridge - Branch Manager </li>
                    <a href="mailto: lynn.lockridge@ky.gov"> lynn.lockridge@ky.gov</a> (502) 564-1181
                    <li> Megan Kissel - DUI Coordinator </li>
                    <a href="mailto: megan.kissel@ky.gov "> megan.kissel@ky.gov</a> (606) 497-0208
                    <li> James Farley - DUI Coordinator</li>
                    <a href="mailto: james.farley@ky.gov">james.farley@ky.gov</a> (502) 330-8205
                    <li> Rae Williams - Training Coordinator</li>
                    <a href="">rae.williams@ky.gov</a> (502) 782-6143
                </ul>
                For out of state DUI questions or those pertaining to ignition interlock please contact the Department of Transportation at (502) 564-1257.
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "DUI Training"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 60,
        content: <>
            All applications must be submitted through the Kentucky Online Gateway (KOG) at <a href="https://kog.chfs.ky.gov/home/">https://kog.chfs.ky.gov/home/</a>.  In order for your application to be considered, all required documents must arrive by the stated deadline.
            Click the DUI Certification Training Dates link in the Related Links box to view a list of dates.  Payment must be received before one will be permitted to attend the training.
        </>
    }
];

export default dUI_Content;