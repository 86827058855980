// Libs:
import React from 'react';
// css:
import "../../../assets/styles/pages/style.css";
import Paragraph from './Paragraph';



function Paragraphs({ items }) {
    return (
        <>
            {items.map((x, index ) => <Paragraph key={index} text={x} />)}
        </>


    );
};
export default Paragraphs;