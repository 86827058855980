import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../../components/Links";

const systemOfCare_Content = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            "A System system of Care care (SOC)* is 'a comprehensive spectrum of effective, services and supports for children, youth, and young adults with or at risk for mental health or other challenges and their families, that is organized into a coordinated network of care, builds meaningful partnerships with families and youth, and is culturally and linguistically responsive, in order to help them to function thrive at home, in school, in the community, and throughout life.' A system of care incorporates mental health promotion, prevention, early identification, and early intervention in addition to treatment to address the needs of all children, youth, and young adults.",
            "For more information, see The Evolution of the SOC Approach 2021 in Related Links.",
            "*Stroul, B.A., Blau, G.M., & Larsen, J. (2021). The Evolution of the System of Care Approach. Baltimore: The Institute for Innovation and Implementation, School of Social Work, University of Maryland."

        ]
    },
    






];

export default systemOfCare_Content;