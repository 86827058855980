// Libs:
import React from 'react';
// css:
import "../../../assets/styles/pages/style.css";


function OrderedList({ items }) {
    return (
        <p>
            <ol>
                {items.map((x, index) => <li key={index}>{x}</li>)}
            </ol>
        </p>
    );
};
export default OrderedList;