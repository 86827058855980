//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
//API Middle man
import useApi from "../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';
// Check if Contact info is null
import BranchInfo from "./BranchInfo";



function CountyList() {

    const { error, processing, getRequest } = useApi();
    const [content, setContent] = useState([]);
    const [selected, setSelected] = useState (null);
    
    useEffect(() => {

        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "crisisnos");
            if (status == 200) {
                setContent(data.data)
            }
            else {
                console.log(error)
            }
        };
        fetchLinks();
    }, []);

    return (

        <>
            <div className="col-md-4 col-md-3">          
                    {/* Return List */}
                    <select className="form-select" onChange={(e) => {
                        // console.log(e.target.value);
                        const c = content?.find((x) => x.county === e.target.value)
                        setSelected(c) 
                        // console.log(selected)  
                    }}>
                        <option>Select a County</option>
                        {
                            processing ? <Spinner animation="border" variant="success" /> : content.map((data, index) => {
                                return (
                                    <option key={index} value={data.county} className="form-select"
                                        
                                    >{data.county}</option>
                                )
                            })
                        }
                    </select>
              
            </div>
            {selected ? <BranchInfo 
            county={selected.county}
            regionNumber={selected.regionNumber}
            regionName={selected.regionName}
            phone={selected.phone}
            countyPhone={selected.countyPhone}
            hospital={selected.hospital}
            hospitalPhone={selected.hospitalPhone}
            /> : null }
        </>

    );
}

export default CountyList;