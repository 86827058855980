//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
//API Middle man
import useApi from "../../../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';

import SanitizeHref from "../../../utils/SanitizeHref";



function LinksTwo({ linkTypeID, ...props }) {

    const [links, setLinks] = useState([]);
    const { error, processing, getRequest } = useApi();

    useEffect(() => {

        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "link/" + linkTypeID);
            if (status == 200) {
                setLinks(data.data)
            }
            else {
                console.log(error)
            }
        };

        fetchLinks();

    }, []);
    return (
        <>
            <p>
                <div className="col-md-4 col-md-3">
                    <ul className="no-bullet">
                        {/* Return Links */}
                        {
                            processing ? <Spinner animation="border" variant="success" /> : links.map((data, index) => {
                                return (
                                    <li><a key={index} target="_blank" className="quick-links" href={SanitizeHref(data.url)}>{data.text}</a></li>
                                )
                            })
                        }
                    </ul>
                </div>
            </p>
        </>
    );
}

export default LinksTwo;