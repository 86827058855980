import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import UnorderedList from "../../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../../components/Links";
import LinksTwo from "../../../layouts/bodyLayouts/helpers/LinksTwo";

const youthAdult_Content = [
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Overview"
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 20,
        content: [
            "The Department for Behavioral Health, Developmental and Intellectual Disabilities (DBHDID) is committed to addressing the systemic needs of youth and young adults with behavioral health and/or substance use issues. Through various grants and programs, DBHDID, the Division of Behavioral Health, is working to ensure that youth and young adults receive services that are more inclusive, person-centered, and relative to their age and experiences.",
            ""
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "Initiatives"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <><b>TAYLRD</b><br/></>,
            "The Transition Age Youth Launching Realized Dreams (TAYLRD) program offers services and supports to youth and young adults aged 16–25 who are struggling with mental health and/or substance use issues. Peer Specialists who are young people with lived behavioral health experience and are specially trained to support young people with behavioral health issues are the key to engaging these young people in much needed services and support.",
            "The TAYLRD uses a drop-in center approach to behavioral health care. The hope is that by providing behavioral health support in an engaging environment, TAYLRD will give youth and young adults a sense of belonging and ownership in their wellness and recovery.",
            "The goal is to empower young people by providing support that interests them, such as peer support services, employment and education support and career planning, life skills support, medication support, support in health care navigation, and age-specific and developmentally appropriate behavioral health services."
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 50,
        content: "iHOPE"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [
            <><b>What is psychosis?</b><br/></>,
            "Psychosis involves the loss of contact with reality. A psychotic episode occurs when a person is unable to tell the difference between what is real and what is only occurring in their minds. There are significant changes in the person’s thoughts, beliefs, perceptions and behaviors when psychosis occurs. ",
            "The person may experience hallucinations, such as hearing, seeing, tasting, smelling, and/or feeling things that are not there, or experience delusions. These experiences can feel very real and distressing it difficult to carry on with regular life tasks such as school, work, and relationships.",
            <><b>What is iHope?</b><br/></>,
            "iHOPE (Helping Others Pursue Excellence) is a specialized, team-based program that provides early intervention services for youth and young adults who are at risk of or have symptoms of psychosis. This program serves young people aged 15–30. Early intervention and support can increase the possibility of  continuing with school, work, and full meaningful lives in the community."
        ]
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 80,
        content: <Links linkTypeID="738"/>
    },
    






];

export default youthAdult_Content;