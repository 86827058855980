//Using the react library
import React from "react";
import { Link } from "react-router-dom";


function Department(props) {
    return (
        

        <div><Link className="dropdown-item" to={props.path}>{props.department}</Link></div>
       
    );
}

export default Department;